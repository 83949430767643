.navbar-launchpad {
  position: fixed;
  height: 80px;
  width: 100%;
  z-index: 999;

  &.opened {
    .navbar-container {
      box-shadow: none !important;
    }
  }

  a {
    text-decoration: none;
  }

  .navbar-container {
    height: 80px;
    z-index: 1000;
    background-color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow 250ms ease-in-out;

    &.shadow {
      box-shadow: 0 4px 4px rgba(black, 0.1);
    }

    .nav-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 20px;

      .logo {
        height: 2em;
        width: 2em;
      }
      .logo-text {
        color: $black;
        line-height: 0;
        margin-block: auto;
        margin-left: 0.5em;
        font-weight: 500;
        font-size: 1.1em !important;
        user-select: none;
        transition: color 250ms ease-in-out;
      }

      &:hover,
      &:focus,
      &:active {
        .logo-text {
          color: $primary;
        }
        outline: none;
      }
    }

    .hamburger-icon {
      height: 40px;
      width: 40px;
      color: $dark;
      cursor: pointer;
      padding-right: 30px;
      margin: 0;
      box-sizing: initial;
    }

    .nav-right {
      display: none;
    }

    @media (min-width: 54.375em) {
      height: 100px;

      .hamburger-icon {
        display: none;
      }

      .nav-left {
        padding-left: 30px;

        .logo {
          height: 2.4em;
          width: 2.4em;
        }
        .logo-text {
          font-size: 1.3em !important;
        }
      }

      .nav-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 30px;

        .nav-link {
          margin-inline: 10px;
          font-weight: 500;
          color: $dark;
          position: relative;
          transition: color 250ms ease-in-out;

          &::after {
            content: "";
            position: absolute;
            bottom: -3px;
            left: 0;
            height: 2px;
            width: 0%;
            background-color: $primary;
            transition: width 250ms ease-in-out;
          }

          &.active {
            color: $primary;
            &::after {
              width: 100%;
            }
          }

          &:hover,
          &:focus,
          &:active {
            outline: none;
            color: $primary;
            &::after {
              width: 100%;
            }
          }

          &.last {
            margin-right: 40px;
          }
        }
      }
    }
  }

  .nav-drawer {
    position: absolute;
    height: auto;
    z-index: -1;
    width: 100%;
    background-color: $white;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    transition: transform 300ms ease-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20px;
    transform: translateY(-320px);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    &.opened {
      transform: translateY(0px);
    }

    .nav-title {
      margin: 20px 0 15px;
      color: $dark;
      padding-right: 30px;
    }

    .nav-link {
      text-decoration: none;
      color: $dark;
      font-weight: 600;
      padding: 10px 0;
    }
    a {
      margin-right: 30px;
    }

    @media (min-width: 54.375em) {
      display: none;
    }
  }
}
