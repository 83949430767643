.accordion-1 {
  .accordion {
    margin-top: 80px;
    margin-bottom: 80px;

    .card {
      margin-top: 0;
      margin-bottom: 0;
      box-shadow: none;
      border-bottom: 1px solid $gray-200;
      background-color: $gray-100;

      .card-header {
        border-bottom: none;

        .btn[aria-expanded="true"] {
          .ni {
            transform: rotate(180deg);
          }
        }

        .btn:active:focus {
          box-shadow: none;
        }
      }

      &:last-child {
        border-bottom: none !important;
      }
      .card-body {
        padding: 10px 60px 30px;
      }
    }
  }
}
