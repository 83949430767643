.apply {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    max-width: 35em;
    text-align: center;

    p {
      font-size: 1.3em;
    }
  }
}
