@import "navbar";
@import "footer";
@import "mission";
@import "portfolio";
@import "how-it-works";
@import "what-we-offer";
@import "success-stories";
@import "login";
@import "apply";
@import "donate";
@import "showcase";

.header-text {
  margin-top: -8em;
}

.showcase-logo {
  height: 50px !important;
  width: 50px !important;
  margin-bottom: 1.5em;
}
