.success-stories {
  padding-block: 3em;
  background-color: $white;

  @media (min-width: 54.375em) {
    padding-block: 6em;
  }

  h2 {
    text-align: center;
  }

  .success-stories-container {
    padding-block: 3em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2em;

    a {
      text-decoration: none;
    }

    .company-container {
      background-color: $white;
      border-radius: 20px;
      height: 18.75em;
      width: 18.75em;
      box-sizing: border-box;
      padding: 2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $default;
      box-shadow: 0px 6px 10px rgba(black, 0.2);
      transition: transform 250ms ease-in-out, box-shadow 250ms ease-in-out;

      h3 {
        align-self: flex-start;
        margin-top: 2.8em;
        color: $default;
      }

      .logo {
        max-height: 6.25em;
        max-width: 15em;
        position: absolute;
        transition: transform 250ms ease-in-out, max-height 250ms ease-in-out;
      }

      .name,
      .description {
        transition: opacity 250ms ease-in-out;
        visibility: hidden;
        opacity: 0;
      }

      &:hover,
      &:focus,
      &:active {
        outline: none;
        transform: translateY(-5px);
        box-shadow: 0px 8px 15px rgba(black, 0.3);

        .logo {
          max-height: 2.5em;
          transform: translateY(-95px);
        }

        .name,
        .description {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
