.showcase {
  img {
    height: 75px !important;
    width: auto !important;

    &.truthable {
      height: 30px !important;
      margin-bottom: 20px;
    }

    @media (min-width: 765px) {
      height: auto !important;
      width: auto !important;

      &.truthable {
        height: auto !important;
        margin-bottom: 20px;
      }
    }
  }

  h4 {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
