.footer-launchpad {
  padding-block: 3em;
  background-color: $primary;
  color: $white;
  position: relative;

  @media (min-width: 54.375em) {
    padding-block: 4em;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .footer-left {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: 10px;

      .logo-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        justify-content: center;
        align-items: center;

        .logo {
          height: 2em;
          width: 2em;
        }
        .logo-text {
          color: $white;
          line-height: 0;
          margin-block: auto;
          margin-left: 0.5em;
          font-weight: 500;
          font-size: 1.1em !important;
          user-select: none;
        }
      }
    }

    .footer-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .socials {
        display: flex;
        flex-direction: row;
        margin-bottom: 3em;
      }
      .socials > * {
        fill: $white;
        height: 2.5em;
        width: 2.5em;
        margin-inline: 0.5em;
      }
      .copyright {
        justify-self: flex-end;
      }
    }

    @media (min-width: 54.375em) {
      flex-direction: row;

      .footer-left {
        .logo-container {
          justify-content: flex-start;
        }
      }

      .footer-right {
        align-items: flex-end;
        .socials {
          margin-bottom: 2em;
        }
      }
    }
  }

  ::selection {
    background-color: $white;
    color: $primary;
  }

  .line-footer {
    position: absolute;
    height: 200px;
    width: 100%;
    top: -198px;
  }
}
