.how-it-works {
  padding-top: 10em;
  padding-bottom: 2em;

  .stage {
    margin-top: 8em;
    margin-bottom: 8em;
  }

  p {
    max-width: 400px;
  }

  .lottie {
    height: 300px;
    width: 300px;
  }
}
