.info {
  max-width: 360px;
  margin: 0 auto;
  padding: 1.5rem;
  text-align: center;
  border-radius: $border-radius;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &.info-hover-default {
    &:hover {
      background-color: $default;
      -webkit-box-shadow: $info-shadow $default;
      -moz-box-shadow: $info-shadow $default;
      box-shadow: $info-shadow $default;
    }
  }
  &.info-hover-primary {
    &:hover {
      background-color: $primary;
      -webkit-box-shadow: $info-shadow $primary;
      -moz-box-shadow: $info-shadow $primary;
      box-shadow: $info-shadow $primary;
    }
  }
  &.info-hover-secondary {
    &:hover {
      background-color: $secondary;
      -webkit-box-shadow: $info-shadow $secondary;
      -moz-box-shadow: $info-shadow $secondary;
      box-shadow: $info-shadow $secondary;
    }
  }
  &.info-hover-info {
    &:hover {
      background-color: $info;
      -webkit-box-shadow: $info-shadow $info;
      -moz-box-shadow: $info-shadow $info;
      box-shadow: $info-shadow $info;
    }
  }
  &.info-hover-warning {
    &:hover {
      background-color: $warning;
      -webkit-box-shadow: $info-shadow $warning;
      -moz-box-shadow: $info-shadow $warning;
      box-shadow: $info-shadow $warning;
    }
  }
  &.info-hover-danger {
    &:hover {
      background-color: $danger;
      -webkit-box-shadow: $info-shadow $danger;
      -moz-box-shadow: $info-shadow $danger;
      box-shadow: $info-shadow $danger;
    }
  }
  &.info-hover-success {
    &:hover {
      background-color: $success;
      -webkit-box-shadow: $info-shadow $success;
      -moz-box-shadow: $info-shadow $success;
      box-shadow: $info-shadow $success;
    }
  }

  .icon {
    transition: transform 0.4s, box-shadow 0.4s;

    &.icon-circle {
      max-width: 80px;
      width: 80px;
      height: 80px;
      margin: 0 auto;
      border-radius: 50%;
      box-shadow: 0px 9px 35px -6px rgba(0, 0, 0, 0.3);
      font-size: $font-size-xs;
      background-color: $white;
      position: relative;

      i {
        line-height: 2.7;
      }
    }
  }

  .info-title {
    margin: $margin-base-vertical + 10 0 $margin-base-vertical;
    padding: 0 15px;
  }
  p {
    padding: 0 15px 0 0;
  }
}

.info-horizontal {
  max-width: 500px !important;
  text-align: left !important;

  .icon {
    float: left;
  }
  .description {
    overflow: hidden;
  }
}

.card {
  .info {
    padding: 40px 0;
    text-align: center;
    position: relative;
  }
}

hr {
  width: 10%;
  height: 1px;
  border-radius: 3px;
  margin-left: 0;

  &.line-primary {
    background-color: $primary;
  }
  &.line-info {
    background-color: $info;
  }
  &.line-success {
    background-color: $success;
  }
  &.line-warning {
    background-color: $warning;
  }
  &.line-danger {
    background-color: $danger;
  }
  &.line-deafult {
    background-color: $default;
  }
}
