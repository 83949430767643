.portfolio {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 80px;

  &.noscroll {
    overflow: hidden;
    position: fixed;
  }

  .company-card {
    //background-color: rgba($primary, 0.1);
    flex-basis: 25%;
    flex-grow: 1;
    box-sizing: border-box;
    min-width: 20em;
    max-width: #{"max(25vw, 20em)"};
    width: calc(25vw);
    height: calc(25vw);
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      color: $primary;
      font-size: 1.8em;
      font-weight: 700;
      margin-bottom: 0;
    }

    &:hover {
      background-color: $primary;
      h2 {
        color: $white;
      }
    }
  }
}
.company-modal {
  visibility: hidden;
  position: fixed;
  height: 100%;
  max-width: 45em;
  right: 0;
  width: 80vw;
  background-color: $white;
  z-index: 1001;
  box-shadow: 0 4px 10px rgba(black, 0.4);
  transform: translateX(#{"max(45em, 80vw)"});
  transition: all 400ms ease-in-out;
  overflow-y: scroll;

  &.active {
    transform: translateX(0);
  }

  a {
    text-decoration: none;
    transition: filter 200ms ease-in-out;
    &:hover {
      filter: brightness(70%);
    }
  }

  .back-icon {
    position: absolute;
    height: 25px;
    width: 25px;
    left: 50px;
    top: 50px;
    cursor: pointer;
    transition: 150ms transform ease-in-out;

    &:hover {
      transform: scale(1.2);
    }
  }

  .content {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $black;

    img {
      height: 4em;
      margin-bottom: 2em;
      //text-align: center;
    }

    h2 {
      font-size: 1.6em !important;
      font-weight: 700;
      margin-bottom: 0.5em;
      color: $black;
    }
    a,
    .subtitle {
      font-size: 1.1em;
      font-weight: 500;
      text-decoration: none;
      margin-bottom: 0.5em;
    }
    .socials {
      display: flex;
      flex-direction: row;
      margin-bottom: 3em;

      a > * {
        height: 2.5em;
        width: 2.5em;
        margin-inline: 0.5em;
      }
    }
  }
  .c-info-container {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    text-align: left;
    width: 100%;

    > * + * {
      margin-top: 2em;
    }

    .c-info {
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      h3 {
        color: $black;
        width: 8em;
        flex-grow: 0;
        font-size: 1.2em;
        font-weight: 500;
      }
      p {
        flex: 1;
      }
    }
  }
  .learn-more {
    margin-top: 2em;
    font-size: 1.3em;
    font-weight: 600;
    max-width: 15em;

    a {
      font-weight: 600;
      color: $primary;
    }
  }
}
.overlay-bg {
  visibility: hidden;
  opacity: 0;
  background-color: rgba(black, 0.4);
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all 400ms ease-in-out;
}
.company-modal,
.overlay-bg {
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
