.mission-launchpad {
  padding-bottom: 4em;
  background-color: $gray-100;

  .mission-container {
    display: flex;
    justify-content: flex-end;

    .mission-content {
      max-width: 35em;
      padding: 2em;
      background-color: $white;
      box-shadow: 0px 8px 10px rgba(black, 0.25);
      border-radius: 40px;
      z-index: 1;
      transition: transform 150ms ease-in;

      &:hover {
        transform: translateY(-5px);
      }
    }
  }

  @media (min-width: 54.375em) {
    padding-top: 4em;
    padding-bottom: 6em;
  }
}
