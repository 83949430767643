.what-we-offer {
  padding-top: 6em;
  margin-bottom: 4em;
  position: relative;

  .wwo-header {
    margin-bottom: 2em;
    display: flex;
    align-items: center;
    flex-direction: column;

    h3 {
      color: $white !important;
    }

    ::selection {
      background-color: $white;
      color: $dark;
    }
  }
  .content {
    text-align: center;
    max-width: 60ch;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    text-align: center;
    color: rgba($white, 0.7);
  }

  .wwo-bubble-set {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 2em;

    .icon {
      z-index: 10;
      background-color: $white;
      color: $default;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 10px rgba(black, 0.3);
      animation: icon 5s ease-in-out infinite;
      padding: 1.2em;
      border-radius: 50%;

      &:nth-of-type(1) {
        align-self: flex-start;
        animation-delay: 1s;
        font-size: 0.8em;
      }
      &:nth-of-type(2) {
        animation-delay: 0s;
        align-self: flex-start;
        font-size: 1.3em;
      }
      &:nth-of-type(3) {
        animation-delay: 3s;
        align-self: flex-start;
        font-size: 0.9em;
      }
      &:nth-of-type(4) {
        animation-delay: 1s;
        align-self: flex-start;
        font-size: 1.4em;
      }
      &:nth-of-type(5) {
        animation-delay: 4s;
        align-self: flex-start;
        font-size: 1em;
      }
      @media (min-width: 54.375em) {
        &:nth-of-type(1) {
          font-size: 1.4em;
          animation-delay: 1s;
        }
        &:nth-of-type(2) {
          font-size: 2.6em;
          animation-delay: 0s;
        }
        &:nth-of-type(3) {
          font-size: 1.7em;
          animation-delay: 3s;
        }
        &:nth-of-type(4) {
          font-size: 2.3em;
          animation-delay: 1s;
        }
        &:nth-of-type(5) {
          font-size: 1.6em;
          animation-delay: 4s;
        }
      }

      @keyframes icon {
        0% {
          transform: translateY(0px);
        }
        50% {
          transform: translateY(-20px);
        }
        100% {
          transform: translateY(0px);
        }
      }
    }
  }
}
